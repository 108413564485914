import { Link } from '@remix-run/react';
import { PrimaryButton } from '~/components/General/PrimaryButton';

export function Navigation() {
    return (
        <header className="navigation sticky left-0 top-0 z-50 backdrop-blur">
            <nav className="px-2 py-2.5 text-white md:px-4 lg:px-6">
                <div className="container flex items-center justify-between md:flex-wrap">
                    <Link to="/">
                        <img
                            src="/logo.svg"
                            className="h-6 invert sm:h-9 md:mr-3"
                            alt="Dating Studio Logo"
                        />
                    </Link>
                    <div className="z-10 flex items-center lg:order-2">
                        <a href="https://app.dating.studio/login">
                            <PrimaryButton>Join!</PrimaryButton>
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    );
}
